

























import { Component, Prop, Vue } from "vue-property-decorator";

interface DialogButton {
  text: string;
  class: string;
}

@Component
export default class Dialog extends Vue {
  @Prop({ default: true }) hasBackdrop!: boolean;
  @Prop({ default: true }) closeable!: boolean;
  @Prop({ required: true }) titleText!: string;
  @Prop({ required: false }) buttons!: string[];

  get dialogButtons(): DialogButton[] {
    return this.buttons ? this.buttons.map((text): DialogButton => {
      if (text[0] === '!') {
        return { text: text.slice(1), class: 'dialog-button dialog-button--primary' };
      } else {
        return {  text, class: 'dialog-button' };
      }
    }) : [];
  }
}
